<template>
  <v-col>
    <v-row class="align-center justify-space-between topRow">
      <v-col class="text-no-wrap pb-0 pr-0">
        <HeaderTitle
          :title="$t('requests')"
          fontSizeTitle="24px"
          fontFamilyTitle="CenturyGothicBold"
          fontSizeSub="16px"
          fontFamilySub="CenturyGothicBold"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pt-0" cols="12">
        <v-tabs show-arrows="always" v-model="activeTab" class="lighWhiteTab">
          <v-tabs-slider style="height: 6px" color="error"> </v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :key="tab.name"
            :to="tab.path"
            exact
            class="text-capitalize textDecorationBold tabsText"
          >
           {{ $t(tab.name) }}
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </v-col>
      <v-col class="pt-0" cols="12">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

/* 
  Name: Requests
  Description: Requests view is the main page for all the requests like demands and cooperations. In this page there is tabs for each type of request to open a page.
  
  Components Used:
    HeaderTitle
*/

import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";

export default {
  name: "Requests",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          name: "demands",
          path: "/requests/demands",
        },
        // {
        //   name: "recommendations",
        //   path: "",
        // },
        // {
        //   name: "assignments",

        //   path: "",
        // },
        // {
        //   name: "suggestions",
        //   path: "",
        // },
        {
          name: "cooperations",
          path: "/requests/cooperations",
        },
        // {
        //   name: "offers",
        //   path: "",
        // },
        // {
        //   name: "deals",
        //   path: "",
        // },
      ],
    };
  },
};
</script>
